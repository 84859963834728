import React from "react"
import { Link } from "gatsby"
import ModalVideo from "react-modal-video"
import PropTypes from "prop-types"
import Container from "../components/container"
import SEO from "../components/seo"

import "./layout.scss"

class Layout extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    const { children } = this.props

    return (
      <div id="page-wrapper">
        <SEO title="Powering our Future Together" />
        <div id="left">
          <div className="container">
            <div id="left-header">
              <div id="primary-logo__wrapper">
                <Link to="/" title="Home">
                  <img id="primary-logo" src={"/logo.svg"} alt="Logo" />
                </Link>
              </div>
              <div id="left__wrapper">
                <p className="left-header__text">
                  Powering our Future Together
                </p>
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="Th6p8wGC8UE"
                  onClose={() => this.setState({ isOpen: false })}
                />

                <a
                  className="left-header__popup"
                  href="#"
                  onClick={this.openModal}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 0C3.6 0 0 3.55 0 8C0 12.45 3.55 16 8 16C12.45 16 16 12.45 16 8C16 3.55 12.4 0 8 0ZM12.35 8.4L6.15 12C5.85 12.2 5.45 11.95 5.45 11.6V4.4C5.45 4.05 5.85 3.8 6.15 4L12.35 7.6C12.7 7.75 12.7 8.2 12.35 8.4Z" />
                  </svg>
                  <span className="label">See what’s Coming Soon!</span>
                </a>
                <p className="left-header__copyright">
                  Powered by&nbsp;
                  <a
                    href="https://www.livehire.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LiveHire
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Container id="right">{children}</Container>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
